import { usePropsObject } from "@/lib/contexts/appContext"
import Block from "@/components/wrappers/block"
import Blocks from "@/components/Blocks"
import TextImageBlock from "@/common/partials/textImageBlock/textImageBlock"
import classNames from "classnames/bind"
import styles from "./content-block.module.css"

export default function ContentBlock({ block, settings }) {
  const { attrs, innerBlocks } = block
  const object = usePropsObject()
  const cx = classNames.bind(styles)

  if (innerBlocks === undefined || innerBlocks.length === 0) {
    return null
  }

  const width = attrs?.width ?? "full"

  const blockSettings = {
    "core/heading": { width: "bleed" },
    "core/paragraph": { width: "bleed" },
    "core/list": { width: "bleed" }
  }

  if (settings?.multiple) {
    const classes = {
      wrapper: true,
      "theme-light-teal": block.attrs.theme === "light-teal",
      "theme-lime": block.attrs.theme === "lime",
      "theme-dark-gray": block.attrs.theme === "dark-gray"
    }

    return (
      <Block block={block} width="bleed" className={cx(classes)}>
        <div className="inner-wrapper">
          <Blocks blocks={innerBlocks} settings={blockSettings} />
        </div>
      </Block>
    )
  }

  return (
    <TextImageBlock
      block={block}
      layout={attrs.layout}
      image={attrs.image}
      imageSize={attrs?.imageSize ?? null}
      hasPlate={attrs.theme !== "transparent"}
      plateColor={object?.theme?.plateColor || attrs.theme}
      padding={attrs.theme !== "transparent"}
      className={styles.single}
      width={width}>
      <Blocks blocks={innerBlocks} settings={blockSettings} />
    </TextImageBlock>
  )
}

import { usePropsObject } from "@/lib/contexts/appContext"
import Image from "next/legacy/image"
import Block from "@/components/wrappers/block"
import styles from "./textImageBlock.module.css"
import classNames from "classnames"
import { themePlateColor, themeTextColor } from "@/lib/helpers/utils"

export default function TextImageBlock({
  block,
  image,
  layout,
  imageSize = null,
  children,
  hovered = false,
  plateColor,
  hasPlate = true,
  padding = true,
  width = "full",
  className
}) {
  const { theme } = usePropsObject()

  if (!plateColor) {
    plateColor = theme?.plateColor
  }

  let textColor = themeTextColor(theme?.txtColor, "text-black-100")
  if (plateColor === "lime") {
    textColor = "text-black-100"
  }

  if (plateColor === "white" && textColor === "text-white") {
    textColor = "text-black-100"
  }

  return (
    <Block
      block={block}
      width="full"
      className={classNames(
        styles.wrapper,
        className,
        styles["layout-" + layout],
        {
          "is-narrow": width === "narrow"
        }
      )}>
      <div
        className={classNames(
          styles.innerWrapper,
          {
            "no-padding": !padding,
            "has-narrow-layout": width === "narrow",
            "layout-right": layout === "right"
          },
          hasPlate ? themePlateColor(plateColor) : "transparent",
          textColor
        )}>
        <div
          className={classNames(styles.contentGrid, {
            "no-image": !image?.url,
            "image-small": !hasPlate && imageSize === "small",
            "image-medium": !hasPlate && imageSize === "medium",
            "no-grid": width === "narrow"
          })}>
          {image?.url && (
            <div className={classNames(styles.imageHolder, "image-wrapper")}>
              <figure
                className={classNames(
                  !imageSize ? styles.figure : "",
                  hovered ? "image-hover-effect" : ""
                )}>
                {!imageSize && image?.url && (
                  <Image
                    className={styles.image}
                    src={image.url}
                    sizes={image.sizes}
                    alt={image.alt}
                    layout="fill"
                    loading="lazy"
                  />
                )}
                {imageSize && image?.url && (
                  <Image
                    className={styles.image}
                    src={image.url}
                    sizes={image.sizes}
                    alt={image.alt}
                    width={image.width}
                    height={image.height}
                    loading="lazy"
                  />
                )}
              </figure>
            </div>
          )}
          <div
            className={classNames(styles.contentWrapper, "content-wrapper", {
              "no-padding": !padding
            })}>
            {children}
          </div>
        </div>
      </div>
    </Block>
  )
}
